import * as tslib_1 from "tslib";
import api from "@/api";
import tool from "@/tool";
import { Component, Mixins, } from "vue-property-decorator";
import Base from "@/templates/list";
import getConfig from "@/config";
let Bill = class Bill extends Mixins(Base) {
    constructor() {
        super(...arguments);
        this.fileList = [];
        this.deptList = [];
        this.calendarList = [];
        this.ledgerEmissionList = [];
        this.tableDataParams = { id: "", typeName: "", countType: "" };
        this.tableData = [];
        this.tableData2Params = { id: "" };
        this.tableData2 = [];
        this.uploadUrl = getConfig().serverApi + "/upload/uploadFile";
        this.uploadHeaders = {
            "X-Auth-Token": this.$store.state.user.token,
        };
        this.formInline = {
            year: "2022",
            month: "04",
            orgId: this.$store.state.user.orgId,
            principalId: this.$store.state.user.id,
            page: {
                size: 10,
                currentPage: 1,
            },
        };
        this.factorType = "1";
        this.tabIndex = "0";
        this.isEnable = 0;
        this.loading = false;
        this.buttonloading = false;
        this.whiteTypeName = ["逸散排放"];
        this.deviceInfoForm = {};
    }
    // private count: number = this.$store.state.count;
    // @Prop(Boolean) private defaultArg: string | undefined;
    // Emit('delemit') private delEmitClick (event: MouseEvent) {}
    // @Ref('aButton') readonly ref!: HTMLButtonElement;
    // computed;
    get currentDept() {
        return this.$store.state.user.orgId;
    }
    get currentMonth() {
        if (!this.calendarList.length) {
            return {};
        }
        var aa = this.calendarList.find((ele) => Number(ele.month) == Number(this.formInline.month));
        return aa;
    }
    created() {
        api.organization.queryOrgTree({}).then((res) => {
            this.deptList = tool.treearr(res.data, true);
            console.log("deptList", this.deptList);
        });
        this.init();
    }
    mounted() { }
    init() {
        this.loading = true;
        this.tableDataParams = { id: "", typeName: "", countType: "" };
        this.tableData = [];
        this.tableData2Params = { id: "" };
        this.tableData2 = [];
        api.ledger
            .queryLedgerMonthStatistics({
            orgId: this.formInline.orgId,
            principalId: this.formInline.principalId,
            year: this.formInline.year,
        })
            .then((res) => {
            var list = [
                "01",
                "02",
                "03",
                "04",
                "05",
                "06",
                "07",
                "08",
                "09",
                "10",
                "11",
                "12",
            ].map((ele) => ({
                month: ele,
                isFinished: 0,
                isWrite: 0,
                checkStatus: 0,
            }));
            var nowDate = new Date();
            list.forEach((ele, index) => {
                ele.isWrite = 1;
                // 历史记录不计算
                if (Number(this.formInline.year) > nowDate.getFullYear() ||
                    (Number(this.formInline.year) == nowDate.getFullYear() &&
                        Number(ele.month) > nowDate.getMonth() + 1)) {
                    ele.isWrite = 0;
                }
                res.data.forEach((ele2) => {
                    if (ele2.month === ele.month) {
                        list[index] = ele2;
                        list[index].isWrite = 0;
                        list[index].isFinished = 1;
                    }
                });
            });
            console.log("list", list);
            this.calendarList = list;
        });
        if (this.tabIndex === "0") {
            this.getLedgerOperateOrganization();
        }
        // 排放源
        return api.emission
            .query({
            principalId: this.formInline.principalId,
            orgId: this.formInline.orgId,
        })
            .then((res) => {
            return api.ledger
                .queryLedgerEmissionList({
                orgId: this.formInline.orgId,
                ledgerDate: this.formInline.year + "-" + this.formInline.month + "-01",
                principalId: this.formInline.principalId,
            })
                .then((res2) => {
                res.data.forEach((ele) => {
                    ele.isFill = false;
                    res2.data.forEach((ele2) => {
                        if (ele.id === ele2.emissionsId) {
                            ele.isFill = true;
                        }
                    });
                });
                this.ledgerEmissionList = res.data;
                console.log("this.ledgerEmissionList", this.ledgerEmissionList);
            });
        });
    }
    getLedgerOperateOrganization() {
        this.loading = true;
        // 产量产值
        api.ledger
            .findLedgerOperateOrganization({
            principalId: this.formInline.principalId,
            orgId: this.formInline.orgId,
            ledgerDate: this.formInline.year + "-" + this.formInline.month + "-01",
        })
            .then((res) => {
            console.log("findLedgerOperateOrganization", res.data);
            if (res.data.ledgerOperateList) {
                res.data.ledgerOperateList.forEach((ele) => {
                    if (ele.fileUrls) {
                        var fileList = [];
                        ele.fileUrls.forEach((ele2) => {
                            console.log("ele2", ele2);
                            fileList.push({
                                uid: ele2,
                                name: tool.getUrlParam(ele2, "fileName"),
                                url: ele2,
                                status: "success",
                            });
                        });
                        ele.fileUrls = fileList;
                    }
                });
            }
            if (res.data.ledgerOperateList) {
                this.tableData2Params = res.data;
                this.tableData2 = res.data.ledgerOperateList;
            }
            this.loading = false;
        });
    }
    handleTabClick() {
        if (this.tabIndex !== "0") {
            this.loading = true;
            api.ledger
                .queryLedgerEmissionDetail({
                emissionsId: this.tabIndex,
                ledgerDate: this.formInline.year + "-" + this.formInline.month + "-01",
            })
                .then((res) => {
                res.data.ledgerFactorList.forEach((ele) => {
                    if (ele.fileUrls) {
                        var fileList = [];
                        ele.fileUrls.forEach((ele2) => {
                            fileList.push({
                                uid: ele2,
                                name: tool.getUrlParam(ele2, "fileName"),
                                url: ele2,
                                status: "success",
                            });
                        });
                        ele.fileUrls = fileList;
                    }
                });
                // if (isNaN(this.isEnable)) {
                //   this.isEnable = res.data.isEnable ? 1 : 0;
                // }
                this.tableDataParams = res.data;
                this.tableData = res.data.ledgerFactorList;
                console.log('res.data.ledgerFactorList', res.data.ledgerFactorList);
                this.loading = false;
            });
        }
        else {
            this.getLedgerOperateOrganization();
        }
    }
    handleChangeEnable(e) {
        if (e === 1) {
            this.loading = true;
            api.ledger
                .queryLedgerEmissionDetail({
                emissionsId: this.tabIndex,
                ledgerDate: this.formInline.year + "-" + this.formInline.month + "-01",
            })
                .then((res) => {
                res.data.ledgerFactorList.forEach((ele) => {
                    if (ele.fileUrls) {
                        var fileList = [];
                        ele.fileUrls.forEach((ele2) => {
                            fileList.push({
                                uid: ele2,
                                name: tool.getUrlParam(ele2, "fileName"),
                                url: ele2,
                                status: "success",
                            });
                        });
                        ele.fileUrls = fileList;
                    }
                });
                this.tableDataParams = res.data;
                this.tableData = res.data.ledgerFactorList;
                this.loading = false;
            });
        }
    }
    handleChangeMonth(item) {
        this.formInline.month = item.month;
        this.tabIndex = "0";
        this.init();
    }
    handleChangeYear(ff) {
        if (ff == "prev" && Number(this.formInline.year) > 2000) {
            this.formInline.year--;
        }
        else if (ff == "next" && Number(this.formInline.year) < 2100) {
            this.formInline.year++;
        }
        this.tabIndex = "0";
        this.init();
    }
    handleNodeClick(data) {
        console.log(data);
    }
    handleChangeFile(file, fileList, row) {
        // 传地址共享
        console.log("file", file);
        if (!file.url && file.response) {
            if (file.response.code !== "200") {
                this.$message.error(file.response.desc);
                fileList.splice(fileList.length - 1, 1);
            }
            file.url = file.response.data.url;
        }
        row.fileUrls = fileList;
    }
    handleAddTableData2Row(data) {
        this.tableData2.push({
            productId: "",
            productUnit: "",
            productCount: "",
            productValue: "",
            fileUrls: [],
        });
    }
    masterFileMax(files, fileList) {
        this.$message.error("证明文件最多上传 1 个文件。");
    }
    handleRemoveTableData2Row(data, index) {
        this.tableData2.splice(index, 1);
    }
    resetForm2() {
        this.tableData2 = [];
    }
    resetForm() {
        this.tableData = [];
    }
    submitForm2() {
        var check = true;
        var check2 = true;
        this.tableData2.forEach((ele) => {
            if (!ele.productId ||
                !ele.productCount ||
                !ele.productValue ||
                !ele.fileUrls) {
                check = false;
            }
            if (ele.fileUrls && ele.fileUrls.length == 0) {
                check2 = false;
            }
        });
        if (!check2) {
            this.$message.error("请上传证明文件");
            return;
        }
        if (!check) {
            this.$message.error("请正确填写必填项");
            return;
        }
        var temp = [];
        this.tableData2.forEach((ele) => {
            temp.push(Object.assign({}, ele));
        });
        this.buttonloading = true;
        var query = Object.assign({}, (this.tableData2Params.id ? this.tableData2Params : {}), { ledgerOperateList: temp }, {
            ledgerDate: this.formInline.year + "-" + this.formInline.month + "-01",
            orgId: this.formInline.orgId,
            principalId: this.formInline.principalId,
        });
        query.ledgerOperateList.forEach((ele) => {
            var list = [];
            ele.fileUrls.forEach((ele2) => {
                list.push(ele2.url + "?fileName=" + ele2.name);
            });
            ele.fileUrls = list;
        });
        api.ledger
            .saveLedgerOperate(query)
            .then(() => {
            this.buttonloading = false;
            this.init();
        })
            .catch((_) => {
            this.buttonloading = false;
        });
    }
    submitForm(tabIndex) {
        var check = true;
        var check2 = true;
        this.tableData.forEach((ele) => {
            ele.isEnable = ele.isEnable ? 1 : 0;
            // 自测值
            if (ele.isEnable && ele.ledgerFactorSelfTestValueList.length > 0) {
                ele.ledgerFactorSelfTestValueList.forEach((ele2) => {
                    if (!ele2.countValue) {
                        check = false;
                    }
                });
            }
            if (!ele.count || !ele.fileUrls) {
                check = false;
            }
            if (ele.fileUrls &&
                ele.fileUrls.length === 0 &&
                !this.whiteTypeName.includes(this.tableDataParams.typeName)) {
                check = false;
                check2 = false;
            }
        });
        if (!check2) {
            this.$message.error("请上传证明文件");
            return;
        }
        if (!check) {
            this.$message.error("请正确填写必填项");
            return;
        }
        var temp = [];
        this.tableData.forEach((ele) => {
            temp.push(Object.assign({}, ele));
        });
        this.buttonloading = true;
        var query = Object.assign({}, (this.tableDataParams.id
            ? this.tableDataParams
            : {
                emissionsId: this.tabIndex,
                countType: this.tableDataParams.countType,
            }), { ledgerFactorList: temp }, {
            ledgerDate: this.formInline.year + "-" + this.formInline.month + "-01",
            isEnable: this.isEnable,
            orgId: this.formInline.orgId,
            principalId: this.formInline.principalId,
        });
        query.ledgerFactorList.forEach((ele) => {
            var list = [];
            ele.fileUrls.forEach((ele2) => {
                list.push(ele2.url + "?fileName=" + ele2.name);
            });
            ele.fileUrls = list;
        });
        if (query.id) {
            api.ledger
                .updateLedgerEmission(query)
                .then(() => {
                this.buttonloading = false;
                this.init().then((_) => {
                    this.handleTabClick();
                });
            })
                .catch((_) => {
                this.buttonloading = false;
            });
        }
        else {
            api.ledger
                .saveLedgerEmission(query)
                .then(() => {
                this.buttonloading = false;
                this.init().then((_) => {
                    this.handleTabClick();
                });
                // this.$router.go(-1)
            })
                .catch((_) => {
                this.buttonloading = false;
            });
        }
    }
    handleRemove(file, fileList) {
        console.log(file, fileList);
    }
    handlePreview(file) {
        console.log(file);
        window.open(file.url, file.name, "_blank");
    }
};
Bill = tslib_1.__decorate([
    Component
], Bill);
export default Bill;
